@font-face {
  font-family: "MetronicPro";
  src: url("./assets/fonts/MetronicPro.ttf") format("woff2"),
    url("./assets/fonts/MetronicPro.ttf") format("woff");
}

@font-face {
  font-family: "Uniwars";
  src: url("./assets/fonts/Uniwars W00 SemiBold Italic.ttf") format("woff2"),
    url("./assets/fonts/Uniwars W00 SemiBold Italic.ttf") format("woff");
  font-weight: 600;
  font-style: italic;
}

body {
  font-family: "Poppins", sans-serif;
  margin: 0;
  font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.MuiFormLabel-root {
  color: #000 !important;
}
.nopaddings {
  padding: 0 !important;
  margin: 0 !important;
}
